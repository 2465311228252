import React from 'react';

export default function PrivacyPolicyPage() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-primary-900 mb-4">Privacy Policy</h1>
        <p className="text-lg text-gray-600 mb-8">
          For ZeroToZen Ecommerce Solutions Private Limited
          <br />
          <span className="text-sm">Effective Date: December 05, 2024</span>
        </p>

        <div className="prose prose-primary max-w-none space-y-8">
          <p className="text-gray-700 leading-relaxed">
            ZeroToZen Ecommerce Solutions Private Limited ("we", "us", or "our") is committed to protecting your privacy. 
            This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website and services.
          </p>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Information We Collect</h2>
            
            <div>
              <h3 className="text-xl font-medium text-primary-700 mb-2">Personal Information You Provide</h3>
              <ul className="list-disc pl-6 text-gray-700">
                <li>Name</li>
                <li>Email address</li>
                <li>Contact information</li>
                <li>Company details</li>
                <li>Payment information</li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-medium text-primary-700 mb-2">Information Collected Automatically</h3>
              <ul className="list-disc pl-6 text-gray-700">
                <li>IP address</li>
                <li>Browser type</li>
                <li>Device information</li>
                <li>Usage data</li>
                <li>Cookies and similar technologies</li>
              </ul>
            </div>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">How We Use Your Information</h2>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Provide and improve our services</li>
              <li>Process transactions</li>
              <li>Communicate with you</li>
              <li>Send marketing and promotional materials</li>
              <li>Analyze usage patterns</li>
              <li>Comply with legal obligations</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Data Storage and Security</h2>
            <p className="text-gray-700">
              We implement reasonable security measures to protect your personal information from unauthorized access, 
              alteration, disclosure, or destruction. These measures include:
            </p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Encryption of sensitive data</li>
              <li>Firewalls and secure servers</li>
              <li>Regular security audits</li>
              <li>Restricted access to authorized personnel only</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Sharing Your Information</h2>
            <p className="text-gray-700">We may share your information with:</p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Service providers and business partners</li>
              <li>Legal and regulatory authorities</li>
              <li>Potential buyers or investors (in the event of a sale or merger)</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Your Privacy Rights</h2>
            <p className="text-gray-700">You have the right to:</p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Access your personal information</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Opt-out of marketing communications</li>
              <li>Object to the processing of your data</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Cookies and Similar Technologies</h2>
            <p className="text-gray-700">
              We use cookies and similar tracking technologies to enhance your browsing experience and analyze website traffic. 
              You can manage your cookie preferences through your browser settings.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Changes to This Privacy Policy</h2>
            <p className="text-gray-700">
              We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting 
              the new Privacy Policy on this page and updating the "Effective Date" at the top.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Contact Us</h2>
            <p className="text-gray-700">
              If you have any questions or concerns about this Privacy Policy, please contact us at:
            </p>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="font-medium text-primary-700">ZeroToZen Ecommerce Solutions Private Limited</p>
              <p className="text-gray-600">21 villa, nambiar Ellegenza phase 1 Gopasandra,</p>
              <p className="text-gray-600">Muthanallur, Bengaluru, Karnataka 560099</p>
              <p className="text-gray-600">Email: contact@ecomsuite.com</p>
              <p className="text-gray-600">Phone: +91 6235874255</p>
            </div>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">Children's Privacy</h2>
            <p className="text-gray-700">
              Our services are not intended for individuals under the age of 13. We do not knowingly collect personal 
              information from children under 13.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">International Data Transfers</h2>
            <p className="text-gray-700">
              Your information may be transferred and processed in countries other than your own. We will take appropriate 
              measures to protect your personal information in accordance with this Privacy Policy and applicable laws.
            </p>
          </section>

          <p className="text-gray-700 font-medium mt-8">
            By using our website and services, you consent to the collection, use, and sharing of your information as 
            described in this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
}
