import sanitizeHtml from 'sanitize-html';

export const sanitizeUserInput = (input: string): string => {
  return sanitizeHtml(input, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
      'a': ['href']
    }
  });
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const sanitizeSearchQuery = (query: string): string => {
  return query.replace(/[<>]/g, '').trim();
};
