import { motion } from 'framer-motion';
import { 
  EnvelopeIcon, 
  BriefcaseIcon, 
  SparklesIcon, 
  RocketLaunchIcon, 
  UserGroupIcon,
  CodeBracketIcon,
  CommandLineIcon,
  WindowIcon,
  MegaphoneIcon,
  ChatBubbleBottomCenterTextIcon
} from '@heroicons/react/24/outline';
import teamCultureImg from '../assets/images/team-culture.jpg';

const positions = [
  {
    title: 'Fullstack Developer',
    type: 'Paid Internship',
    skills: ['React JS', 'Python', 'Full Stack Development'],
    description: 'Looking for a passionate fullstack developer to join our team. You will work on building and maintaining our web applications using React JS and Python.',
    icon: CodeBracketIcon,
    color: 'bg-purple-100 text-purple-600',
  },
  {
    title: 'Backend Developer',
    type: 'Paid Internship',
    skills: ['Python', 'AWS', 'Database Management'],
    description: 'Join us as a backend developer to work on our cloud infrastructure and database systems. Experience with Python and AWS is required.',
    icon: CommandLineIcon,
    color: 'bg-blue-100 text-blue-600',
  },
  {
    title: 'Frontend Developer',
    type: 'Paid Internship',
    skills: ['React JS', 'Node JS', 'CSS', 'HTML'],
    description: 'We are seeking a frontend developer to create beautiful and responsive user interfaces. Strong knowledge of React JS and modern web technologies is required.',
    icon: WindowIcon,
    color: 'bg-emerald-100 text-emerald-600',
  },
  {
    title: 'Sales and Marketing Intern',
    type: 'Paid Internship',
    skills: ['Digital Marketing', 'Social Media Management', 'Lead Generation', 'English', 'Hindi', 'Communication'],
    description: 'Zero To Zen Technology Solutions seeks a motivated Sales and Marketing Intern to generate leads among Amazon and e-commerce sellers through multi-channel campaigns and manage our social media presence. The ideal candidate will be a graduate or MBA student with strong communication skills in English and Hindi (mandatory), with Tamil, Telugu, or Kannada as a plus.',
    icon: MegaphoneIcon,
    color: 'bg-rose-100 text-rose-600',
  },
];

const benefits = [
  {
    title: 'Growth Opportunities',
    description: 'Continuous learning and career advancement paths',
    icon: RocketLaunchIcon,
  },
  {
    title: 'Collaborative Culture',
    description: 'Work with a diverse and talented team',
    icon: UserGroupIcon,
  },
  {
    title: 'Innovation Focus',
    description: 'Work on cutting-edge technologies',
    icon: SparklesIcon,
  },
];

export default function Careers() {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-br from-primary-600 via-primary-700 to-primary-800 py-24">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,_rgba(255,255,255,0.1),_transparent_70%)]"></div>
        <div className="container relative">
          <motion.div
            className="mx-auto max-w-2xl text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Join Our Team
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-100">
              Be part of our mission to revolutionize e-commerce with cutting-edge technology.
            </p>
          </motion.div>
        </div>
      </div>

      {/* Culture Section */}
      <div className="py-24 sm:py-32">
        <div className="container">
          <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:gap-x-12">
            <motion.div
              className="relative"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <div className="aspect-[4/3] overflow-hidden rounded-2xl">
                <img
                  src={teamCultureImg}
                  alt="Team Culture"
                  className="w-full h-full object-cover"
                />
              </div>
            </motion.div>

            <motion.div
              className="flex flex-col justify-center"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Why Work With Us?
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Join a team of passionate individuals working together to create innovative solutions for e-commerce businesses.
              </p>
              <div className="mt-10 space-y-8">
                {benefits.map((benefit) => (
                  <div key={benefit.title} className="flex gap-x-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-primary-600">
                      <benefit.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">{benefit.title}</h3>
                      <p className="mt-2 text-gray-600">{benefit.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Open Positions */}
      <div className="py-24 bg-gray-50">
        <div className="container">
          <motion.div
            className="mx-auto max-w-2xl text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Open Positions
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Find your perfect role and grow with us
            </p>
          </motion.div>

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {positions.map((position, index) => (
              <motion.div
                key={position.title}
                className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden hover:shadow-lg transition-all group"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="p-6">
                  <div className="flex items-center mb-4">
                    <div className={`p-2 rounded-lg ${position.color} group-hover:scale-110 transition-transform`}>
                      <position.icon className="h-6 w-6" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900 ml-3">
                      {position.title}
                    </h3>
                  </div>
                  <div className="mb-4">
                    <span className={`inline-flex items-center rounded-full ${position.color} px-3 py-1 text-sm font-medium`}>
                      {position.type}
                    </span>
                  </div>
                  <p className="text-gray-600 mb-4">
                    {position.description}
                  </p>
                  <div className="space-y-2">
                    <h4 className="text-sm font-medium text-gray-900">Required Skills:</h4>
                    <div className="flex flex-wrap gap-2">
                      {position.skills.map((skill) => (
                        <span
                          key={skill}
                          className={`inline-flex items-center rounded-full bg-opacity-50 ${position.color} px-2 py-1 text-xs font-medium`}
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={`border-t border-gray-100 p-4 bg-opacity-50 ${position.color}`}>
                  <a 
                    href={`mailto:hr@ecomsuite.com?subject=${encodeURIComponent(`Job application for ${position.title}`)}`}
                    className="w-full flex items-center justify-center gap-2 text-sm font-medium text-gray-900 hover:text-gray-700 transition-colors"
                  >
                    <ChatBubbleBottomCenterTextIcon className="h-4 w-4" />
                    Apply Now
                  </a>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            className="mt-16 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <div className="mx-auto max-w-2xl rounded-2xl bg-primary-50 p-8">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Interested in joining our team?
              </h3>
              <p className="text-gray-600 mb-6">
                Send your resume and a brief introduction to our HR team. We'd love to hear from you!
              </p>
              <a
                href="mailto:hr@ecomsuite.com"
                className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary-600 px-6 py-3 text-white hover:bg-primary-700 transition-colors"
              >
                <EnvelopeIcon className="h-5 w-5" />
                Send Resume
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
