import React from 'react';
import { motion } from 'framer-motion';
import {
  ChartBarIcon,
  CurrencyDollarIcon,
  CogIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/outline';

const benefits = [
  {
    name: 'Advanced Analytics',
    description: 'Gain deep insights into your business performance with real-time analytics and customizable dashboards.',
    icon: ChartBarIcon,
    stats: ['45% increase in data accuracy', '2x faster decision making'],
  },
  {
    name: 'Cost Optimization',
    description: 'Identify and eliminate inefficiencies to reduce operational costs while maximizing ROI.',
    icon: CurrencyDollarIcon,
    stats: ['30% reduction in operational costs', '25% improvement in resource allocation'],
  },
  {
    name: 'Process Automation',
    description: 'Streamline operations with intelligent automation that reduces manual tasks and human error.',
    icon: CogIcon,
    stats: ['80% reduction in manual tasks', '60% faster processing time'],
  },
  {
    name: 'Business Intelligence',
    description: 'Transform raw data into actionable insights that drive strategic decision-making.',
    icon: LightBulbIcon,
    stats: ['360° view of business metrics', 'Real-time market insights'],
  },
  {
    name: 'Risk Management',
    description: 'Identify and mitigate potential risks before they impact your business operations.',
    icon: ShieldCheckIcon,
    stats: ['90% risk prediction accuracy', '50% fewer operational incidents'],
  },
  {
    name: 'Scalable Growth',
    description: 'Build a foundation that grows with your business, from startup to enterprise.',
    icon: ArrowTrendingUpIcon,
    stats: ['3x faster scaling capacity', 'Unlimited growth potential'],
  },
];

export default function Benefits() {
  return (
    <div className="bg-gradient-to-b from-white via-primary-50 to-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <motion.h2
            className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            Transform Your Business with Zero To Zen
          </motion.h2>
          <motion.p
            className="mt-6 text-lg leading-8 text-gray-600"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Unlock your business potential with our comprehensive suite of solutions designed to drive growth and efficiency.
          </motion.p>
        </div>
        <div className="mx-auto mt-16 max-w-7xl sm:mt-20 lg:mt-24">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {benefits.map((benefit, index) => (
              <motion.div
                key={benefit.name}
                className="relative bg-white pl-16 pr-8 py-8 rounded-2xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-4 top-8 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-600">
                    <benefit.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {benefit.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {benefit.description}
                  <ul className="mt-4 space-y-2">
                    {benefit.stats.map((stat, statIndex) => (
                      <motion.li
                        key={statIndex}
                        className="text-sm text-primary-600 font-medium flex items-center"
                        initial={{ opacity: 0, x: -10 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.3, delay: (index * 0.1) + (statIndex * 0.1) }}
                      >
                        <svg
                          className="mr-2 h-4 w-4 text-primary-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        {stat}
                      </motion.li>
                    ))}
                  </ul>
                </dd>
              </motion.div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
