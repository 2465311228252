import React from 'react';
import { motion } from 'framer-motion';
import {
  ChartBarIcon,
  CursorArrowRaysIcon,
  ShoppingCartIcon,
  ChartPieIcon,
  UserGroupIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';

const features = [
  {
    title: 'Inventory Predictions',
    description:
      'AI-powered inventory predictions to optimize your stock levels and prevent stockouts or overstock situations.',
    icon: ChartBarIcon,
  },
  {
    title: 'Listing Optimization',
    description:
      'Data-driven recommendations to improve your product listings and increase visibility in Amazon search results.',
    icon: CursorArrowRaysIcon,
  },
  {
    title: 'PPC Management',
    description:
      'Automated PPC campaign optimization to maximize your advertising ROI and reduce ACoS.',
    icon: RocketLaunchIcon,
  },
  {
    title: 'Sales Analytics',
    description:
      'Comprehensive sales analytics and reporting to track your business performance and identify growth opportunities.',
    icon: ChartPieIcon,
  },
  {
    title: 'Competitor Analysis',
    description:
      'Monitor your competitors\' prices, rankings, and strategies to stay ahead in the marketplace.',
    icon: UserGroupIcon,
  },
  {
    title: 'Order Management',
    description:
      'Streamlined order processing and fulfillment tracking to ensure customer satisfaction.',
    icon: ShoppingCartIcon,
  },
];

export default function Features() {
  return (
    <div className="py-24 bg-white">
      <div className="container">
        <div className="lg:text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">
              Features
            </h2>
            <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Everything you need to succeed on Amazon
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Our comprehensive suite of tools helps you optimize every aspect of your
              Amazon business, from inventory management to marketing.
            </p>
          </motion.div>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
            {/* Left Side Images */}
            <motion.div 
              className="lg:col-span-4 flex flex-col gap-8"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              {/* Main Image */}
              <div className="relative w-full">
                <div className="absolute inset-0 bg-gradient-to-r from-primary-100 to-transparent rounded-2xl transform rotate-2"></div>
                <img 
                  src="/images/dashboard.jpg"
                  alt="Analytics Dashboard" 
                  className="relative z-10 w-full h-auto rounded-2xl shadow-lg object-cover"
                />
              </div>
              
              {/* Additional Images */}
              <div className="grid grid-cols-2 gap-4">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-primary-100/80 to-transparent rounded-xl transform -rotate-1"></div>
                  <img 
                    src="/images/analytics.jpg"
                    alt="Analytics Insights" 
                    className="relative z-10 w-full h-auto rounded-xl shadow-md object-cover aspect-square"
                  />
                </div>
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-l from-primary-100/80 to-transparent rounded-xl transform rotate-1"></div>
                  <img 
                    src="/images/automation.jpg"
                    alt="Process Automation" 
                    className="relative z-10 w-full h-auto rounded-xl shadow-md object-cover aspect-square"
                  />
                </div>
              </div>
            </motion.div>

            {/* Right Side Features Grid */}
            <div className="lg:col-span-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                {features.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    className="relative p-6 bg-white rounded-2xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <div className="text-3xl mb-4">
                      {<feature.icon className="h-8 w-8 text-primary-600" aria-hidden="true" />}
                    </div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600">
                      {feature.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
