interface MetaData {
  title: string;
  description?: string;
  keywords?: string;
  ogImage?: string;
}

export const updateMetadata = ({ title, description, keywords, ogImage }: MetaData) => {
  // Update title
  document.title = `${title} | Zero to Zen Solutions`;

  // Update meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription && description) {
    metaDescription.setAttribute('content', description);
  }

  // Update meta keywords
  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords && keywords) {
    metaKeywords.setAttribute('content', keywords);
  }

  // Update OG title
  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute('content', title);
  }

  // Update OG description
  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription && description) {
    ogDescription.setAttribute('content', description);
  }

  // Update OG image
  const ogImageElement = document.querySelector('meta[property="og:image"]');
  if (ogImageElement && ogImage) {
    ogImageElement.setAttribute('content', ogImage);
  }
};
