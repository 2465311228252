import React from 'react';

export default function TermsPage() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-primary-900 mb-4">Terms of Service</h1>
        <p className="text-lg text-gray-600 mb-8">
          For ZeroToZen Ecommerce Solutions Private Limited
          <br />
          <span className="text-sm">Last Updated: December 05, 2024</span>
        </p>

        <div className="prose prose-primary max-w-none space-y-8">
          <p className="text-gray-700 leading-relaxed">
            These Terms of Service ("Terms") govern your use of the website and services operated by ZeroToZen Ecommerce Solutions Private Limited ("we", "us", or "our"). By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use our services.
          </p>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">1. Acceptance of Terms</h2>
            <p className="text-gray-700">
              By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Policy. Continued use of our website or services implies ongoing agreement to these Terms.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">2. Description of Services</h2>
            <p className="text-gray-700">
              ZeroToZen Ecommerce Solutions Private Limited provides e-commerce solutions, consulting services, and related technology services to businesses. Our services include, but are not limited to:
            </p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>E-commerce platform development and maintenance</li>
              <li>Digital transformation consulting</li>
              <li>Technology implementation services</li>
              <li>Custom software development</li>
              <li>Support and maintenance services</li>
            </ul>
            <p className="text-gray-700">
              We reserve the right to modify, suspend, or discontinue any part of our services at any time without prior notice.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">3. Limited License</h2>
            <p className="text-gray-700">
              ZeroToZen Ecommerce Solutions Private Limited grants you a non-exclusive, non-transferable, limited license to use the Site in accordance with this Agreement.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">4. User Accounts</h2>
            <p className="text-gray-700">When you create an account with us:</p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>You must provide accurate, complete, and current information.</li>
              <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
              <li>You agree to notify us immediately of any unauthorized use of your account.</li>
              <li>We reserve the right to suspend or terminate your account if any information provided is inaccurate, outdated, or incomplete.</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">5. User Responsibilities</h2>
            <p className="text-gray-700">As a visitor to this Site or account holder, you agree not to:</p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Use the Site for any illegal or unauthorized purpose</li>
              <li>Post or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or invasive of another's privacy</li>
              <li>Attempt to gain unauthorized access to any portion of the Site or any other systems connected to the Site</li>
              <li>Interfere with or disrupt the Site or servers or networks connected to the Site</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">6. Intellectual Property</h2>
            <p className="text-gray-700">
              The service and its original content, features, and functionality are and will remain the exclusive property of ZeroToZen Ecommerce Solutions Private Limited and its licensors. Our services are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of our materials without our express written consent.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">7. Payment Terms</h2>
            <p className="text-gray-700">For services requiring payment:</p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>You agree to provide accurate and complete payment information.</li>
              <li>You authorize us to charge your designated payment method for all services you purchase.</li>
              <li>All fees are exclusive of applicable taxes, which will be added to your total amount due.</li>
              <li>Payments are non-refundable unless otherwise specified in writing.</li>
              <li>We reserve the right to modify our pricing with at least 30 days' notice via email or website updates.</li>
              <li>Non-payment may result in suspension or termination of services.</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">8. Warranty Disclaimer</h2>
            <p className="text-gray-700">
              Your use of the Site is at your sole risk. The Site is provided on an "as is" and "as available" basis, without warranties of any kind, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
            </p>
            <p className="text-gray-700">ZeroToZen Ecommerce Solutions Private Limited, its subsidiaries, affiliates, and licensors do not warrant that:</p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>The Site will function uninterrupted, securely, or be available at any particular time or location</li>
              <li>Any errors or defects will be corrected</li>
              <li>The Site is free of viruses or other harmful components</li>
              <li>The results of using the Site will meet your requirements</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">9. Limitation of Liability</h2>
            <p className="text-gray-700">To the fullest extent permitted by applicable law:</p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>ZeroToZen Ecommerce Solutions Private Limited, its directors, employees, partners, agents, suppliers, or affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages.</li>
              <li>Our total liability for any claims arising under these Terms shall not exceed the amount you paid us for services in the 12 months preceding the claim.</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">10. Indemnification Procedure</h2>
            <p className="text-gray-700">
              If any claim, suit, or proceeding (referred to as a Claim) is brought against us within the scope of an indemnity you have provided under these Terms, we will:
            </p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Notify you of the Claim at your most recent email address in our records</li>
              <li>Conduct the defense of the Claim with your assistance as reasonably requested</li>
              <li>Require your express written approval for any settlement or compromise</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">11. Links to Other Sites</h2>
            <p className="text-gray-700">
              Our Site may contain links to third-party sites that are not owned or controlled by ZeroToZen Ecommerce Solutions Private Limited. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. We strongly advise you to read the terms and conditions and privacy policy of any third-party site you visit.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">12. Responsibility to Protect Against Viruses</h2>
            <p className="text-gray-700">
              While we have no intention of distributing harmful electronic materials, it is your responsibility to ensure that any downloads from our Site are free of viruses and other destructive items. We are not liable for any virus or malware introduced to your computer system through access to or download from our Site.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">13. Termination</h2>
            <p className="text-gray-700">
              We may terminate or suspend your account and access to our services immediately, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination:
            </p>
            <ul className="list-disc pl-6 text-gray-700">
              <li>Your right to use the service will immediately cease.</li>
              <li>We may delete any data or content associated with your account.</li>
              <li>You remain liable for all amounts due up to and including the date of termination.</li>
            </ul>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">14. Changes to Terms</h2>
            <p className="text-gray-700">
              We reserve the right to modify these Terms at any time. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our services after those revisions become effective, you agree to be bound by the revised Terms.
            </p>
            <p className="text-gray-700">
              Please review this Agreement periodically for changes. If you do not agree to any of this Agreement or any changes to this Agreement, do not use, access or continue to access the Site or discontinue any use of the Site immediately.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">15. Governing Law and Dispute Resolution</h2>
            <p className="text-gray-700">
              These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in Bangalore, Karnataka. Before pursuing legal action, both parties agree to attempt to resolve any disputes through good-faith negotiation for a period of not less than 30 days.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-primary-800">16. Contact Information</h2>
            <p className="text-gray-700">
              For any questions about these Terms, please contact us at:
            </p>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="font-medium text-primary-700">ZeroToZen Ecommerce Solutions Private Limited</p>
              <p className="text-gray-600">21 villa, nambiar Ellegenza phase 1 Gopasandra,</p>
              <p className="text-gray-600">Muthanallur, Bengaluru, Karnataka 560099</p>
              <p className="text-gray-600">Email: contact@ecomsuite.com</p>
              <p className="text-gray-600">Phone: +91 6235874255</p>
            </div>
          </section>

          <p className="text-gray-700 font-medium mt-8">
            By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
          </p>
        </div>
      </div>
    </div>
  );
}
