import React from 'react';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="relative isolate overflow-hidden bg-gradient-to-b from-white to-primary-50">
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-20">
        <div className="mx-auto max-w-2xl flex-1 lg:mx-0 lg:max-w-xl lg:pt-8">
          <h1 className="mt-8 text-5xl font-bold tracking-tight text-gray-900 sm:text-7xl">
            Empower Your Amazon Business
          </h1>
          <p className="mt-4 text-lg font-medium text-primary-600">
            Empowering Your Tech Journey to Zen!
          </p>
          <p className="mt-6 text-xl leading-8 text-gray-600">
            Transform your Amazon selling experience with our comprehensive suite of tools. Get deep insights, optimize listings, and boost your sales with AI-powered solutions.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Link
              to="/features"
              className="rounded-md bg-primary-600 px-5 py-3 text-base font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              Get started
            </Link>
            <Link to="/contact" className="text-base font-semibold leading-6 text-gray-900">
              Contact sales <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:max-w-none lg:flex-1">
          <div className="relative w-full">
            <img
              src="/images/hero-analytics.jpg"
              alt="eComSuite Analytics Dashboard"
              className="rounded-lg shadow-2xl ring-1 ring-gray-200/10 transform hover:scale-105 transition-transform duration-300 object-cover object-center max-h-[600px] w-full"
              width="1920"
              height="1080"
            />
            <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
