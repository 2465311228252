import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  ArrowLeftIcon, 
  ClockIcon, 
  TagIcon,
  CalendarIcon,
  ShareIcon,
} from '@heroicons/react/24/outline';
import { updateMetadata } from '../utils/seo';

// Extended blog posts data with content sections
const posts = [
  {
    id: 1,
    title: '10 Proven Strategies to Boost Your Amazon Sales in 2024',
    description: 'Learn the latest techniques and best practices to increase your Amazon sales and stay ahead of the competition.',
    date: 'Mar 16, 2024',
    category: 'Sales Strategy',
    readTime: '8 min read',
    imageUrl: 'https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2400&q=80',
    content: [
      {
        type: 'introduction',
        content: 'In the ever-evolving world of e-commerce, staying ahead of the competition on Amazon requires a combination of strategic thinking, data-driven decision making, and consistent execution. This guide will walk you through 10 proven strategies that can help boost your Amazon sales in 2024.'
      },
      {
        type: 'section',
        title: '1. Optimize Your Product Listings',
        content: 'Your product listings are often the first point of contact between your brand and potential customers. Here\'s how to make them count:',
        bullets: [
          'Use high-quality images from multiple angles',
          'Write compelling, keyword-rich titles',
          'Create detailed, scannable bullet points',
          'Include relevant search terms in your backend keywords'
        ]
      },
      {
        type: 'section',
        title: '2. Leverage Amazon\'s A+ Content',
        content: 'A+ Content (formerly Enhanced Brand Content) allows brand-registered sellers to enhance their product descriptions with rich text, images, and story-telling components. This can significantly increase conversion rates and reduce returns.'
      },
      {
        type: 'section',
        title: '3. Implement a Dynamic Pricing Strategy',
        content: 'Price optimization is crucial for maintaining competitiveness while preserving margins. Consider using repricing tools to: Monitor competitor prices, automatically adjust prices within set parameters, and maintain Buy Box eligibility.',
        bullets: [
          'Monitor competitor prices',
          'Automatically adjust prices within set parameters',
          'Maintain Buy Box eligibility'
        ]
      },
      {
        type: 'section',
        title: '4. Focus on Inventory Management',
        content: 'Effective inventory management is crucial for maintaining good seller metrics and avoiding stockouts. Use tools to:',
        bullets: [
          'Forecast demand accurately',
          'Set up automatic reordering',
          'Monitor storage fees and aged inventory',
          'Optimize inventory turnover'
        ]
      }
    ]
  },
  // ... other posts
];

export default function BlogPostPage() {
  const { id } = useParams();
  const post = posts.find(post => post.id === Number(id));

  useEffect(() => {
    if (post) {
      updateMetadata({
        title: post.title,
        description: post.description,
        keywords: `${post.category}, Amazon seller, e-commerce, ${post.title.toLowerCase()}, Zero to Zen Solutions`,
        ogImage: post.imageUrl
      });
    }
  }, [post]);

  if (!post) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900">Blog post not found</h2>
          <Link to="/blog" className="mt-4 text-primary-600 hover:text-primary-500">
            Return to blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-white"
    >
      {/* Back button */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Link
          to="/blog"
          className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ArrowLeftIcon className="mr-2 h-4 w-4" />
          Back to blog
        </Link>
      </div>

      {/* Hero section with image */}
      <div className="relative h-[400px] lg:h-[600px] overflow-hidden">
        <img
          src={post.imageUrl}
          alt={post.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="max-w-3xl">
            <div className="flex items-center space-x-4 text-white/80 text-sm mb-4">
              <span className="inline-flex items-center">
                <TagIcon className="h-4 w-4 mr-1" />
                {post.category}
              </span>
              <span className="inline-flex items-center">
                <ClockIcon className="h-4 w-4 mr-1" />
                {post.readTime}
              </span>
              <span className="inline-flex items-center">
                <CalendarIcon className="h-4 w-4 mr-1" />
                {post.date}
              </span>
            </div>
            <h1 className="text-4xl font-bold text-white sm:text-5xl">{post.title}</h1>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="max-w-3xl">
          <div className="flex items-center space-x-4 border-b border-gray-200 pb-8">
            <button className="ml-auto inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50">
              <ShareIcon className="h-4 w-4 mr-2" />
              Share
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="max-w-3xl mx-auto prose prose-lg prose-primary">
          {post.content.map((section, index) => (
            <div key={index} className="mb-8">
              {section.type === 'introduction' ? (
                <p className="text-xl text-gray-600 leading-relaxed">{section.content}</p>
              ) : (
                <>
                  <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">{section.title}</h2>
                  <p className="text-gray-600 leading-relaxed">{section.content}</p>
                  {section.bullets && (
                    <ul className="mt-4 space-y-2">
                      {section.bullets.map((bullet, bulletIndex) => (
                        <li key={bulletIndex} className="flex items-start">
                          <span className="mr-2 mt-1.5 h-1.5 w-1.5 rounded-full bg-primary-600" />
                          <span className="text-gray-600">{bullet}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Related posts section could be added here */}
    </motion.div>
  );
}
