import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ClockIcon, CalendarIcon, ChevronRightIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { updateMetadata } from '../utils/seo';
import { sanitizeSearchQuery } from '../utils/security';

// Extended blog posts data
const posts = [
  {
    id: 1,
    title: '10 Proven Strategies to Boost Your Amazon Sales in 2024',
    description: 'Learn the latest techniques and best practices to increase your Amazon sales and stay ahead of the competition.',
    date: 'Mar 16, 2024',
    category: 'Sales Strategy',
    author: 'Krishna',
    readTime: '8 min read',
    imageUrl: 'https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
  {
    id: 2,
    title: 'Understanding Amazon\'s A10 Algorithm: A Complete Guide',
    description: 'Dive deep into Amazon\'s latest algorithm update and learn how to optimize your listings for better visibility.',
    date: 'Mar 14, 2024',
    category: 'SEO',
    author: 'Arya',
    readTime: '6 min read',
    imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
  {
    id: 3,
    title: 'Mastering Inventory Management: Tips from Top Sellers',
    description: 'Top Amazon sellers share their secrets for effective inventory management and forecasting.',
    date: 'Mar 12, 2024',
    category: 'Inventory',
    author: 'Sandeep',
    readTime: '5 min read',
    imageUrl: 'https://images.unsplash.com/photo-1553413077-190dd305871c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
  {
    id: 4,
    title: 'The Future of E-commerce: AI and Machine Learning',
    description: 'Explore how artificial intelligence is reshaping the e-commerce landscape and how sellers can leverage it.',
    date: 'Mar 10, 2024',
    category: 'Technology',
    author: 'Sangeetha',
    readTime: '7 min read',
    imageUrl: 'https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  },
  {
    id: 5,
    title: 'PPC Campaign Optimization: Advanced Techniques',
    description: 'Advanced strategies for optimizing your Amazon PPC campaigns to reduce ACoS and increase ROI.',
    date: 'Mar 8, 2024',
    category: 'Marketing',
    author: 'Priya',
    readTime: '10 min read',
    imageUrl: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80'
  }
];

const categories = Array.from(new Set(posts.map(post => post.category)));

export default function BlogPage() {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    updateMetadata({
      title: 'Blog - Latest Insights & Updates',
      description: 'Stay up to date with the latest trends, strategies, and best practices in e-commerce and Amazon selling. Expert insights from Zero to Zen Solutions.',
      keywords: 'Amazon seller blog, e-commerce tips, Amazon selling strategies, business intelligence, data analytics',
      ogImage: '%PUBLIC_URL%/blog-og-image.jpg'
    });
  }, []);

  const filteredPosts = posts.filter(post => {
    const matchesCategory = selectedCategory ? post.category === selectedCategory : true;
    const matchesSearch = searchQuery.trim() === '' ? true : 
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.category.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedQuery = sanitizeSearchQuery(event.target.value);
    setSearchQuery(sanitizedQuery);
  };

  return (
    <div className="bg-white">
      {/* Hero section */}
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-primary-100/20">
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          <div className="mx-auto max-w-4xl lg:mx-0 text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
            >
              Latest Insights & Updates
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="mt-6 text-lg leading-8 text-gray-600"
            >
              Stay up to date with the latest trends, strategies, and best practices in e-commerce and Amazon selling.
            </motion.p>
          </div>
        </div>
      </div>

      {/* Search and filter section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col sm:flex-row gap-4 items-center justify-between">
          {/* Search input */}
          <div className="relative w-full sm:w-96">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search blogs..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            />
          </div>

          {/* Category filters */}
          <div className="flex flex-wrap gap-2 justify-center sm:justify-end">
            <button
              onClick={() => setSelectedCategory(null)}
              className={`px-4 py-2 rounded-full text-sm font-medium ${
                selectedCategory === null
                  ? 'bg-primary-500 text-white'
                  : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
              }`}
            >
              All
            </button>
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full text-sm font-medium ${
                  selectedCategory === category
                    ? 'bg-primary-500 text-white'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Blog posts grid */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-24">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {filteredPosts.map((post, index) => (
            <motion.article
              key={post.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex flex-col bg-white rounded-2xl shadow-sm ring-1 ring-gray-200/50 hover:shadow-lg transition-shadow duration-300"
            >
              <div className="relative">
                <img
                  src={post.imageUrl}
                  alt={post.title}
                  className="aspect-[16/9] w-full rounded-t-2xl object-cover"
                />
                <div className="absolute top-4 left-4">
                  <span className="inline-flex items-center rounded-full bg-white/90 backdrop-blur-sm px-3 py-1 text-sm font-medium text-gray-800">
                    {post.category}
                  </span>
                </div>
              </div>
              <div className="flex-1 p-6">
                <div className="flex items-center space-x-4 text-sm text-gray-500 mb-4">
                  <span className="inline-flex items-center">
                    <CalendarIcon className="h-4 w-4 mr-1" />
                    {post.date}
                  </span>
                  <span className="inline-flex items-center">
                    <ClockIcon className="h-4 w-4 mr-1" />
                    {post.readTime}
                  </span>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {post.title}
                </h3>
                <p className="text-gray-600 mb-4 line-clamp-2">
                  {post.description}
                </p>
                <div className="mt-auto flex items-center justify-between">
                  <span className="text-sm font-medium text-gray-900">
                    {post.author}
                  </span>
                  <Link
                    to={`/blog/${post.id}`}
                    className="inline-flex items-center text-sm font-medium text-primary-600 hover:text-primary-700"
                  >
                    Read more
                    <ChevronRightIcon className="ml-1 h-4 w-4" />
                  </Link>
                </div>
              </div>
            </motion.article>
          ))}
        </div>
      </div>
    </div>
  );
}
