import React from 'react';
import {
  ChartBarIcon,
  CursorArrowRaysIcon,
  ShoppingCartIcon,
  ChartPieIcon,
  UserGroupIcon,
  RocketLaunchIcon,
  PresentationChartLineIcon,
  CogIcon,
  BoltIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const features = [
  {
    name: 'Advanced Analytics Dashboard',
    description: 'Get a comprehensive view of your business performance with our intuitive analytics dashboard. Track key metrics, monitor trends, and make data-driven decisions.',
    icon: PresentationChartLineIcon,
    color: 'bg-blue-100 text-blue-600',
    category: 'Analytics',
  },
  {
    name: 'Inventory Forecasting',
    description: 'AI-powered inventory predictions to optimize your stock levels. Prevent stockouts and overstock situations with machine learning algorithms that analyze historical data and market trends.',
    icon: ChartBarIcon,
    color: 'bg-purple-100 text-purple-600',
    category: 'AI & Machine Learning',
  },
  {
    name: 'Listing Optimization',
    description: 'Enhance your product listings with data-driven recommendations. Improve visibility in search results and boost conversion rates with optimized titles, bullets, and descriptions.',
    icon: CursorArrowRaysIcon,
    color: 'bg-pink-100 text-pink-600',
    category: 'Optimization',
  },
  {
    name: 'PPC Campaign Management',
    description: 'Maximize your advertising ROI with our automated PPC management system. Optimize bids, target relevant keywords, and reduce ACoS with intelligent campaign strategies.',
    icon: RocketLaunchIcon,
    color: 'bg-orange-100 text-orange-600',
    category: 'Marketing',
  },
  {
    name: 'Performance Analytics',
    description: 'Track your business metrics with detailed analytics and custom reports. Identify growth opportunities and optimize your operations with actionable insights.',
    icon: ChartPieIcon,
    color: 'bg-green-100 text-green-600',
    category: 'Analytics',
  },
  {
    name: 'Market Intelligence',
    description: 'Stay ahead of the competition with comprehensive market analysis. Monitor competitor prices, rankings, and strategies while identifying market trends and opportunities.',
    icon: UserGroupIcon,
    color: 'bg-indigo-100 text-indigo-600',
    category: 'Market Research',
  },
  {
    name: 'Order Management',
    description: 'Streamline your order processing and fulfillment tracking. Ensure customer satisfaction with efficient order management and automated notifications.',
    icon: ShoppingCartIcon,
    color: 'bg-red-100 text-red-600',
    category: 'Operations',
  },
  {
    name: 'Automation Tools',
    description: 'Save time and reduce errors with our suite of automation tools. Automate routine tasks, pricing updates, and inventory management.',
    icon: CogIcon,
    color: 'bg-teal-100 text-teal-600',
    category: 'Automation',
  },
  {
    name: 'Real-time Alerts',
    description: 'Stay informed with instant notifications about important changes in your business. Get alerts for price changes, stock levels, and performance metrics.',
    icon: BoltIcon,
    color: 'bg-yellow-100 text-yellow-600',
    category: 'Monitoring',
  },
];

const categories = Array.from(new Set(features.map(feature => feature.category)));

export default function FeaturesPage() {
  return (
    <div className="bg-white">
      {/* Hero section */}
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-primary-100/20">
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
          <div className="mx-auto max-w-4xl lg:mx-0 text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
            >
              Powerful Features for Your Business Growth
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="mt-6 text-lg leading-8 text-gray-600"
            >
              Discover our comprehensive suite of tools designed to help you scale your business,
              optimize operations, and maximize profitability.
            </motion.p>
          </div>
        </div>
      </div>

      {/* Features section */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-24">
        {categories.map((category, categoryIndex) => (
          <div key={category} className="mb-16">
            <h2 className="text-2xl font-semibold text-gray-900 mb-8">{category}</h2>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features
                .filter(feature => feature.category === category)
                .map((feature, featureIndex) => (
                  <motion.div
                    key={feature.name}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: featureIndex * 0.1 }}
                    className="relative bg-white p-8 rounded-2xl shadow-sm ring-1 ring-gray-200/50 hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="flex items-center">
                      <div className={`p-2 rounded-lg ${feature.color}`}>
                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <h3 className="ml-4 text-lg font-semibold leading-8 text-gray-900">
                        {feature.name}
                      </h3>
                    </div>
                    <p className="mt-4 text-base leading-7 text-gray-600">{feature.description}</p>
                  </motion.div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
