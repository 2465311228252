import React from 'react';
import { Helmet } from 'react-helmet-async';
import Careers from '../components/Careers';

export default function CareersPage() {
  return (
    <>
      <Helmet>
        <title>Careers - Zero To Zen</title>
        <meta
          name="description"
          content="Join our team at Zero To Zen and be part of revolutionizing e-commerce with cutting-edge technology."
        />
      </Helmet>
      <Careers />
    </>
  );
}
