import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import LiveChat from '../components/LiveChat';

const offices = [
  {
    city: 'Bengaluru',
    address: '21 villa, nambiar Ellegenza phase 1 Gopasandra, Muthanallur, Bengaluru, Karnataka 560099',
    phone: '+91 6235874255',
    email: 'contact@ecomsuite.com',
    mapUrl: 'https://maps.app.goo.gl/dNDTcVUoxnqwDBpt8'
  }
];

const contactFaqs = [
  {
    question: "What are your support hours?",
    answer: "Our support team is available 24/7 to assist you. We typically respond to inquiries within 2-4 hours during business hours and within 8 hours outside of business hours."
  },
  {
    question: "How quickly can I expect a response?",
    answer: "For general inquiries, we aim to respond within 24 hours. Premium customers receive priority support with response times under 2 hours."
  },
  {
    question: "Do you offer phone support?",
    answer: "Yes, phone support is available for all premium customers. Standard customers can schedule phone consultations through our online booking system."
  },
  {
    question: "Can I request a demo of your platform?",
    answer: "Absolutely! You can schedule a personalized demo through our contact form or by clicking the 'Request Demo' button in the navigation bar."
  }
];

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
};

export default function ContactPage() {
  const [formData, setFormData] = React.useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = React.useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');

    // TODO: Implement actual form submission
    // This is a mock implementation
    setTimeout(() => {
      setStatus('success');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      });
    }, 1000);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Contact Us</h1>
        <p className="text-lg text-gray-500">We'd love to hear from you</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Contact Information */}
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Get in Touch</h2>
          
          <div className="space-y-6">
            {offices.map((office, index) => (
              <div key={office.city} className="flex items-start">
                <FaMapMarkerAlt className="text-primary-600 text-xl mt-1 mr-4" />
                <div>
                  <h3 className="text-lg font-medium text-gray-900">{office.city}</h3>
                  <a 
                    href={office.mapUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-500 hover:text-primary-600"
                  >
                    {office.address}
                  </a>
                  <div className="flex items-center mt-2">
                    <FaPhone className="text-primary-600 text-xl mt-1 mr-4" />
                    <a href={`tel:${office.phone}`} className="text-gray-500 hover:text-primary-600">
                      {office.phone}
                    </a>
                  </div>
                  <div className="flex items-center mt-2">
                    <FaEnvelope className="text-primary-600 text-xl mt-1 mr-4" />
                    <a href={`mailto:${office.email}`} className="text-gray-500 hover:text-primary-600">
                      {office.email}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Contact Form */}
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Send us a Message</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                value={formData.message}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={status === 'loading' || status === 'success'}
              className="w-full bg-primary-600 text-white px-4 py-2 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              {status === 'loading' ? 'Sending...' : status === 'success' ? 'Message Sent!' : 'Send Message'}
            </button>
            {status === 'success' && (
              <p className="mt-4 text-sm text-green-600 text-center">
                Thank you for your message! We'll get back to you soon.
              </p>
            )}
            {status === 'error' && (
              <p className="mt-4 text-sm text-red-600 text-center">
                Something went wrong. Please try again later.
              </p>
            )}
          </form>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="mx-auto mt-24 max-w-2xl">
        <h2 className="text-2xl font-bold text-center text-gray-900">
          Frequently Asked Questions
        </h2>
        <div className="mt-8 space-y-4">
          {contactFaqs.map((faq, index) => (
            <div key={index} className="bg-gray-50 rounded-lg">
              <div className="flex w-full justify-between rounded-lg px-4 py-4 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                <span className="text-base text-gray-900">{faq.question}</span>
              </div>
              <div className="px-4 pb-4 text-sm text-gray-600">
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Live Chat */}
      <div className="mt-12">
        <LiveChat />
      </div>
    </div>
  );
}
