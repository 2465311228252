import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    question: "What is eComSuite and how can it help my Amazon business?",
    answer: "eComSuite is a comprehensive suite of tools designed specifically for Amazon sellers. It helps you optimize inventory management, improve product listings, manage PPC campaigns, track analytics, and monitor competitors - all from one integrated platform."
  },
  {
    question: "How accurate are your inventory forecasting predictions?",
    answer: "Our AI-powered inventory forecasting system typically achieves 90%+ accuracy by analyzing historical sales data, seasonal trends, market conditions, and various other factors to provide precise stock level recommendations."
  },
  {
    question: "Do you offer integration with other e-commerce platforms?",
    answer: "While we currently specialize in Amazon marketplace optimization, we're actively working on expanding our integration capabilities to include other major e-commerce platforms. Stay tuned for updates!"
  },
  {
    question: "What kind of support do you provide?",
    answer: "We offer 24/7 customer support through chat, email, and phone. Our team of e-commerce experts is always ready to help you maximize your success on Amazon."
  },
  {
    question: "Is there a free trial available?",
    answer: "Yes! We offer a 14-day free trial with full access to all features, allowing you to experience the full potential of eComSuite before making a commitment."
  }
];

export default function FAQ() {
  return (
    <div className="bg-gray-50 py-24">
      <div className="container">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <p className="mt-4 text-center text-lg text-gray-600">
            Have a different question? Reach out to our support team.
          </p>
          <div className="mt-12 space-y-4">
            {faqs.map((faq, index) => (
              <Disclosure as="div" key={index} className="bg-white rounded-lg shadow">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-4 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <span className="text-base text-gray-900">{faq.question}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-primary-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-4 text-sm text-gray-600">
                      {faq.answer}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
