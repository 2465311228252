/**
 * Navbar Component
 * 
 * A responsive navigation bar that provides site-wide navigation with the following features:
 * - Responsive design with mobile menu support
 * - Dynamic route highlighting for active pages
 * - Smooth transitions and animations
 * - Mobile slide-in menu from the right side
 * 
 * @component
 */

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import '../styles/logo.css';

// Navigation items configuration
const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Features', href: '/features' },
  { name: 'Blogs', href: '/blog' },
  { name: 'Contact Us', href: '/contact' },
];

/**
 * Utility function to combine CSS classes conditionally
 * @param classes - Array of class names to combine
 * @returns Combined class names string with falsy values filtered out
 */
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

/**
 * Main Navbar component
 * @returns JSX.Element
 */
export default function Navbar() {
  // Get current route for active link highlighting
  const location = useLocation();

  return (
    <Disclosure as="nav" className="bg-white/90 backdrop-blur-md fixed w-full top-0 z-50 shadow-sm border-b border-gray-100">
      {({ open }) => (
        <>
          {/* Main Navigation Bar */}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-20 items-center justify-between">
              <div className="flex flex-1 items-center justify-between sm:items-stretch">
                {/* Logo and Company Name */}
                <div className="flex flex-shrink-0 items-center">
                  <Link 
                    to="/" 
                    className="group flex items-center space-x-2"
                  >
                    {/* Logo with enhanced 3D circular effect */}
                    <div className="relative logo-container">
                      <div className="absolute inset-0 bg-gradient-to-br from-primary-700 to-primary-800 rounded-full transform transition-all duration-500 logo-back"></div>
                      <div className="absolute inset-0 bg-gradient-to-br from-primary-600 to-primary-700 rounded-full transform transition-all duration-500 logo-middle"></div>
                      <div className="relative bg-gradient-to-r from-primary-500 to-primary-600 text-white w-12 h-12 rounded-full flex items-center justify-center transform transition-all duration-500 logo-front">
                        <span className="logo-text">
                          <span className="text-xl font-bold inline-block">Z</span>
                        </span>
                      </div>
                    </div>
                    {/* Company name and tagline */}
                    <div className="flex flex-col">
                      <div className="relative">
                        <span className="absolute -top-0.5 left-[2.75em] text-[0.7rem] font-medium text-gray-600" style={{ width: '4em', letterSpacing: '0.02em' }}>zero to</span>
                        <span className="text-5xl font-bold text-primary-600 tracking-tight">zen</span>
                      </div>
                      <span className="text-xs text-gray-500 font-medium mt-1">Technology Solutions</span>
                    </div>
                  </Link>
                </div>

                {/* Desktop Navigation Menu */}
                <div className="hidden sm:flex sm:items-center">
                  <div className="flex space-x-8 mr-8">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          // Active link styling
                          location.pathname === item.href
                            ? 'text-primary-600 font-semibold'
                            : 'text-gray-600 hover:text-gray-900',
                          'px-3 py-2 text-sm font-medium transition-colors duration-200',
                          location.pathname === item.href ? 'border-b-2 border-primary-500' : ''
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  {/* Call-to-action button */}
                  <Link
                    to="/contact"
                    className="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 transition-colors duration-200"
                  >
                    Get Started
                  </Link>
                </div>

                {/* Mobile Menu Button */}
                <div className="flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                    <span className="sr-only">Open main menu</span>
                    {/* Toggle between menu and close icons */}
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Navigation Menu Panel */}
          <Disclosure.Panel className="sm:hidden">
            <div className="fixed inset-y-[81px] right-0 w-64 bg-white/95 backdrop-blur-md shadow-lg border-l border-gray-100 z-40 h-[calc(100vh-81px)]">
              <div className="px-2 pt-2 pb-3 space-y-1 bg-white">
                {/* Mobile Navigation Links */}
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      location.pathname === item.href
                        ? 'bg-primary-50 text-primary-600 font-semibold'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'block px-3 py-2 rounded-md text-base font-medium transition-colors duration-200'
                    )}
                    onClick={() => {
                      // Close mobile menu when a link is clicked
                      const button = document.querySelector('[aria-label="Close main menu"]');
                      if (button) {
                        (button as HTMLButtonElement).click();
                      }
                    }}
                  >
                    {item.name}
                  </Link>
                ))}
                {/* Mobile Call-to-action Button */}
                <div className="pt-4">
                  <Link
                    to="/contact"
                    className="block w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 transition-colors duration-200"
                    onClick={() => {
                      // Close mobile menu when CTA is clicked
                      const button = document.querySelector('[aria-label="Close main menu"]');
                      if (button) {
                        (button as HTMLButtonElement).click();
                      }
                    }}
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
