import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const reasons = [
  {
    title: 'End-to-End Solutions',
    description: 'From initial concept to final implementation, we guide you through every step of your digital transformation journey.',
    icon: '🎯'
  },
  {
    title: 'Proven Expertise',
    description: 'Our team brings years of experience in developing scalable, secure, and innovative technology solutions.',
    icon: '💡'
  },
  {
    title: 'Customer-Centric Approach',
    description: 'We prioritize understanding your unique needs and challenges to deliver tailored solutions that drive real business value.',
    icon: '🤝'
  },
  {
    title: 'Continuous Innovation',
    description: 'Stay ahead of the curve with our commitment to implementing cutting-edge technologies and best practices.',
    icon: '🚀'
  }
];

const benefits = [
  'Streamlined business processes',
  'Enhanced operational efficiency',
  'Improved customer experience',
  'Data-driven decision making',
  'Scalable infrastructure',
  'Future-proof solutions'
];

export default function WhyUs() {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-primary-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <motion.h2 
            className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            Why Zero To Zen?
          </motion.h2>
          <motion.p 
            className="mt-4 text-lg leading-8 text-gray-600"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Transform your business from complexity to clarity with our comprehensive technology solutions
          </motion.p>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 mb-16">
          {reasons.map((reason, index) => (
            <motion.div
              key={reason.title}
              className="relative p-6 bg-white rounded-2xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="text-3xl mb-4">{reason.icon}</div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {reason.title}
              </h3>
              <p className="text-gray-600">
                {reason.description}
              </p>
            </motion.div>
          ))}
        </div>

        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
          <motion.h3 
            className="text-2xl font-semibold text-gray-900 mb-6 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            What You'll Gain
          </motion.h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {benefits.map((benefit, index) => (
              <motion.div
                key={benefit}
                className="flex items-center space-x-2"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <CheckCircleIcon className="h-5 w-5 text-primary-500" />
                <span className="text-gray-700">{benefit}</span>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
