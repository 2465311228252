/**
 * Footer Component
 * 
 * A responsive footer component that includes:
 * - Company information and branding
 * - Navigation links organized by categories
 * - Social media links
 * - Copyright information
 * 
 * The footer is responsive and adjusts its layout based on screen size:
 * - Mobile: Single column layout
 * - Tablet: Two column layout
 * - Desktop: Four column layout with logo section
 * 
 * @component
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import '../styles/logo.css';

// Navigation links organized by category
const footerNavigation = {
  solutions: [
    { name: 'Technology Consulting', href: '/solutions/consulting' },
    { name: 'Digital Transformation', href: '/solutions/transformation' },
    { name: 'Cloud Solutions', href: '/solutions/cloud' },
    { name: 'Custom Development', href: '/solutions/development' },
  ],
  support: [
    { name: 'Documentation', href: '/support/docs' },
    { name: 'Guides', href: '/support/guides' },
    { name: 'API Status', href: '/support/status' },
  ],
  company: [
    { name: 'About', href: '/about' },
    { name: 'Blog', href: '/blog' },
    { name: 'Careers', href: '/careers' },
    { name: 'Press', href: '/press' },
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Terms of Service', href: '/terms' },
  ],
  social: [
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/ecomsuite/',
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <FaLinkedin className="h-6 w-6" {...props} />
      ),
    },
  ],
};

/**
 * Footer component that displays company information and navigation links
 * @returns JSX.Element
 */
export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary-50 border-t border-primary-100">
      {/* Hidden heading for accessibility */}
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      {/* Main footer content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company branding and social section */}
          <div className="col-span-1">
            <div className="flex flex-col space-y-4">
              <Link to="/" className="flex items-center space-x-2">
                {/* Logo with enhanced 3D circular effect */}
                <div className="relative logo-container">
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-700 to-primary-800 rounded-full transform transition-all duration-500 logo-back"></div>
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-600 to-primary-700 rounded-full transform transition-all duration-500 logo-middle"></div>
                  <div className="relative bg-gradient-to-r from-primary-500 to-primary-600 text-white w-12 h-12 rounded-full flex items-center justify-center transform transition-all duration-500 logo-front">
                    <span className="logo-text">
                      <span className="text-xl font-bold inline-block">Z</span>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-lg font-semibold text-gray-900">Zero To Zen Technology Solutions</span>
                  <span className="text-sm text-gray-500">Empowering Your Tech Journey to Zen!</span>
                </div>
              </Link>
              
              {/* Social Links */}
              <div className="pt-4">
                <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                  Follow Us
                </h3>
                <div className="mt-4 flex space-x-6">
                  {footerNavigation.social.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-900 hover:text-gray-700"
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
              Company
            </h3>
            <ul className="mt-4 space-y-4">
              {footerNavigation.company.map((item) => (
                <li key={item.name}>
                  <Link to={item.href} className="text-base text-gray-900 hover:text-gray-700">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
              Legal
            </h3>
            <ul className="mt-4 space-y-4">
              {footerNavigation.legal.map((item) => (
                <li key={item.name}>
                  <Link to={item.href} className="text-base text-gray-900 hover:text-gray-700">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-8 border-t border-primary-100 pt-8">
          <p className="text-base text-gray-900 text-center">
            &copy; {currentYear} Zero To Zen. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
