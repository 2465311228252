import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import BlogPreview from '../components/BlogPreview';
import WhyUs from '../components/WhyUs';
import Benefits from '../components/Benefits';
import FAQ from '../components/FAQ';
import Newsletter from '../components/Newsletter';

export default function HomePage() {
  return (
    <div>
      <Hero />
      <Features />
      <Benefits />
      <BlogPreview />
      <WhyUs />
      <FAQ />
      <Newsletter />
    </div>
  );
}
